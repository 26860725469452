import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BasicDetails.css';
import Cookies from 'js-cookie';
const PopupForm = ({ onClose, onDetailsSubmitted }) => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const expirationTimeInMinutes = 20;
  const expirationTimeInDays = expirationTimeInMinutes / (24 * 60);
  
  
  useEffect(() => {
    // Load data from local storage when component mounts
    const storedName = Cookies.get('name');
    const storedAge = Cookies.get('age');
    const storedGender = Cookies.get('gender');
    const storedAdditionalInfo = Cookies.get('additionalInfo');

    if (storedName) setName(storedName);
    if (storedAge) setAge(storedAge);
    if (storedGender) setGender(storedGender);
    if (storedAdditionalInfo) setAdditionalInfo(storedAdditionalInfo);
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name || !age || !gender) {
      alert('Please fill out all required fields.');
      return;
    }

    // Save data to local storage
    Cookies.set('name', name, { expires: expirationTimeInDays });
    Cookies.set('age', age, { expires: expirationTimeInDays });
    Cookies.set('gender', gender, { expires: expirationTimeInDays });
    Cookies.set('additionalInfo', additionalInfo, { expires: expirationTimeInDays });
    // Trigger onDetailsSubmitted callback and close the form
    onDetailsSubmitted();
    onClose();
  };

  return (
    <div className="popup-form-overlay">
      <div className="popup-form-content">
        <button className="popup-close" onClick={onClose}>✖️</button>
        <h3>Enter Your Details</h3>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input 
              type="text" 
              value={name} 
              onChange={(e) => {
                setName(e.target.value);
                Cookies.set('name', e.target.value, { expires: expirationTimeInDays });
              }} 
              required 
            />
          </label>
          <label>
            Age:
            <input 
              type="number" 
              value={age} 
              onChange={(e) => {
                setAge(e.target.value);
                Cookies.set('age', e.target.value, { expires: expirationTimeInDays });
              }} 
              required 
            />
          </label>
          <label>
            Gender:
            <select 
              value={gender} 
              onChange={(e) => {
                setGender(e.target.value);
                Cookies.set('gender', e.target.value, { expires: expirationTimeInDays });
              }} 
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Additional Info:
            <textarea 
              value={additionalInfo} 
              onChange={(e) => {
                setAdditionalInfo(e.target.value);
                Cookies.set('additionalInfo', e.target.value, { expires: expirationTimeInDays });
              }} 
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;