import React, { useEffect, useRef, useState } from 'react';
import './sidebar.css';
import settings from '../images/settings.png';
import expand from '../images/expand.png';
import messages from '../images/help.png';
import favorites from '../images/favourite.png';
import chathistory from '../images/chathistory.png';
import samplequestions from '../images/samplequestions.png';
import logo from '../images/logo.png'; // Import logo image
import home from '../images/home.png';
import AvatarPage from './avatarpage';
import avatar from '../images/avatar.png';
import avatar1 from '../images/avatar1.png';
import avatar2 from '../images/avatar2.png';
import avatar3 from '../images/avatar3.png';
import avatar4 from '../images/avatar4.png';
import avatar5 from '../images/avatar5.png';
import avatar6 from '../images/avatar6.png';
import axios from 'axios';
import Cookies from 'js-cookie';

// Array of all image imports
const imagesToPreload = [
    settings, expand, messages, favorites, chathistory, samplequestions,
    logo, home, avatar, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6
];

// Function to preload images
const preloadImages = (images) => {
    images.forEach((image) => {
        const img = new Image();
        img.src = image;
    });
};

const Sidebar = ({ onExit, onToggleQuestions, onToggleAvatar, onToggleWidth, isExpanded, onAvatarSelect, onQuestionClick, isQueryDisabled, likedResponses, dislikedResponses }) => {
    const [darkMode, setDarkMode] = useState(false);
    const [activeItem, setActiveItem] = useState('messages'); // Set default to 'messages'
    const [showSubcategories, setShowSubcategories] = useState(true);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [isAvatarPageVisible, setAvatarPageVisible] = useState(true);
    const [isSampleQuestionsVisible, setSampleQuestionsVisible] = useState(true);
    const [isEnglish, setIsEnglish] = useState(true);
    const questionsPageRef = useRef(null);
    const [chatHistory, setChatHistory] = useState({});
    const [chatSessions, setChatSessions] = useState([]); // For chat sessions
    const [isChatHistoryVisible, setChatHistoryVisible] = useState(false);
    const [isAvatarSubcategoriesVisible, setAvatarSubcategoriesVisible] = useState(null);
    const [isLikedResponsesVisible, setLikedResponsesVisible] = useState(false);
    const [isDislikedResponsesVisible, setDislikedResponsesVisible] = useState(false);
    const [isFavoritesVisible, setFavoritesVisible] = useState(false);
    const userId = localStorage.getItem('userId');
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    

    const handleExit = () => {
        onExit(); // This will trigger the exit and navigate to the landing page
    };

    // Set active item to 'messages' if not expanded
    useEffect(() => {
        if (!isExpanded) {
            setActiveItem('messages'); // Ensure 'messages' is selected on page load
            setShowSubcategories(true); // Keep the sidebar always expanded initially
        }
    }, [isExpanded]);

    useEffect(() => {
        preloadImages(imagesToPreload);
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                setIsSidebarVisible(false);
            } else {
                setIsSidebarVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        const userId = Cookies.get('userId');
        if (userId) {
            fetchChatSessions(userId);
        }
    }, [userId]);

    const fetchChatSessions = async (userId) => {
        try {
            const response = await axios.get(`https://backend-586306968006.us-central1.run.app/api/chat_sessions/${userId}`);
            if (response.status === 200 && response.data.chat_sessions) {
                setChatSessions(response.data.chat_sessions);
            } else {
                console.error('No chat sessions found or invalid response format.');
            }
        } catch (error) {
            console.error('Error fetching chat sessions:', error.message);
        }
    };

    const fetchChatHistory = async (sessionId) => {
        try {
            const response = await axios.get(`https://backend-586306968006.us-central1.run.app/api/chat_history/${userId}/${sessionId}`);
            if (response.status === 200 && response.data.chat_history) {
                setChatHistory(response.data.chat_history);
            } else {
                console.error('No chat history found or invalid response format.');
            }
        } catch (error) {
            console.error('Error fetching chat history:', error.message);
        }
    };

    // Add this line
    // Rest of the component code remains the same
    useEffect(() => {
        document.body.classList.toggle("dark-mode", darkMode);
    }, [darkMode]);


    // Sample questions related to domestic violence
    const englishQuestions = [
        "What resources are available for individuals experiencing domestic violence?",
        "How can someone safely leave an abusive relationship?",
        "What are the warning signs of an abusive partner?",
        "How can friends and family support someone who is facing domestic violence?",
        "What legal protections exist for victims of domestic violence?",
        "How can we raise awareness about domestic violence in our communities?",
        "What steps can organizations take to provide support to employees affected by domestic violence?",
        "How does domestic violence affect children, and what can be done to help them?",
        "What role can men play in preventing domestic violence?",
        "What are the long-term effects of domestic violence on survivors?",
    ];

    const urduQuestions = [
        "گھریلو تشدد کا شکار ہونے والے افراد کے لیے کون سے وسائل دستیاب ہیں؟",
        "کوئی شخص محفوظ طریقے سے ایک خطرناک رشتہ چھوڑنے کے لیے کیا کر سکتا ہے؟",
        "ایک تشدد کرنے والے شریک حیات کی انتباہی علامات کیا ہیں؟",
        "دوستوں اور خاندان کے افراد کس طرح کسی شخص کی مدد کر سکتے ہیں جو گھریلو تشدد کا سامنا کر رہا ہے؟",
        "گھریلو تشدد کے شکار افراد کے لیے کون سے قانونی تحفظات موجود ہیں؟",
        "ہم اپنے معاشروں میں گھریلو تشدد کے بارے میں آگاہی کیسے بڑھا سکتے ہیں؟",
        "تنظیمیں ایسے ملازمین کی مدد کے لیے کیا اقدامات کر سکتی ہیں جو گھریلو تشدد کا شکار ہیں؟",
        "گھریلو تشدد بچوں پر کس طرح اثر انداز ہوتا ہے، اور ان کی مدد کے لیے کیا کیا جا سکتا ہے؟",
        "مردوں کا گھریلو تشدد کی روک تھام میں کیا کردار ہو سکتا ہے؟",
        "گھریلو تشدد کے شکار افراد پر طویل مدتی اثرات کیا ہیں؟",
    ];


    const toggleLanguage = () => {
        setIsEnglish((prev) => !prev);
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        setAvatarSubcategoriesVisible(item === 'avatar' ? !isAvatarSubcategoriesVisible : false);
        setSampleQuestionsVisible(item === 'messages' ? !isSampleQuestionsVisible : false);
        setFavoritesVisible(item === 'favorites' ? !isFavoritesVisible : false);
        setChatHistoryVisible(item === 'chatHistory' ? !isChatHistoryVisible : false);
        setFavoritesVisible(item === 'favorites' ? !isFavoritesVisible : false);

        // Reset liked and disliked responses visibility when switching to favorites
        if (item === 'favorites') {
            setLikedResponsesVisible(false);
            setDislikedResponsesVisible(false);
        }

        switch (item) {
            case 'avatar':
                onToggleAvatar();
                break;
            case 'messages':
                onToggleQuestions();
                break;
            case 'favorites':
                // Handle favorites action
                break;
            case 'settings':
                // Open settings modal
                break;
            case 'home':
                // Handle home action
                break;
            case 'expand':

                break;
            default:
                break;
        }
    };

    const handleAvatarSelect = (avatarSrc) => {
        setSelectedAvatar(avatarSrc);
        onAvatarSelect(avatarSrc);
        setAvatarPageVisible(avatarSrc !== null);
    };

    const handleDarkModeToggle = () => {
        setDarkMode(!darkMode);
    };


    const sidebarItems = [
        { id: 'expand', icon: expand, text: 'Stop Abuse Now' },
        { id: 'avatar', icon: avatar, text: 'Avatar' },
        // { id: 'chatHistory', icon: chathistory, text: 'Chat History' },
        { id: 'messages', icon: samplequestions, text: 'Sample Questions' },
        { id: 'favorites', icon: favorites, text: 'Favourited' },
        { id: 'home', icon: home, text: 'Home' },
    ];

    const bottomItems = [
        { id: 'settings', icon: settings, text: 'Settings' },
    ];

    const toggleSampleQuestions = () => {
        console.log("Toggling sample questions, current state:", isSampleQuestionsVisible);
        setSampleQuestionsVisible(!isSampleQuestionsVisible);
    };
    const name = Cookies.get('name');

    // Find the active item text based on the activeItem
    const activeItemText = sidebarItems.find(item => item.id === activeItem)?.text || 'Gender DEI';

    const renderChatHistory = () => {
        if (!chatSessions.length) {
            return <p className="no-chat-message">No chat sessions available.</p>;
        }

        return (
            <ul className="chat-history-list">
                {chatSessions.map((session) => (
                    <li
                        key={session.session_id}
                        className="chat-history-item"
                        onClick={() => fetchChatHistory(session.session_id)}
                    >
                        {session.title}
                    </li>
                ))}
            </ul>
        );
    };



    const renderDetailedContent = () => {
        return (
            <div className="detailed-content">


                {activeItem === 'avatar' && (
                    <ul>
                        <li
                            onClick={() => setAvatarSubcategoriesVisible(isAvatarSubcategoriesVisible === 'appearance' ? null : 'appearance')}
                            className={isAvatarSubcategoriesVisible === 'appearance' ? 'active' : ''}
                        >
                            Appearance
                            {/* <img src={avatar} alt="Avatar" className="right-icon" /> */}
                        </li>
                        {isAvatarSubcategoriesVisible === 'appearance' && (
                            <ul className="subcategory-list">
                                <li onClick={() => handleAvatarSelect(avatar1)}>
                                    {/* <img src={selectedAvatar} alt="avatar" className="avatar-thumbnail" /> */}
                                    Display avatar
                                </li>
                                <li onClick={() => handleAvatarSelect(null)}>
                                    {/* <img src={avatar} alt="Hide Avatar" className="avatar-thumbnail" /> */}
                                    Hide Avatar
                                </li>
                            </ul>
                        )}

                        <li
                            onClick={() => setAvatarSubcategoriesVisible(isAvatarSubcategoriesVisible === 'customize' ? null : 'customize')}
                            className={isAvatarSubcategoriesVisible === 'customize' ? 'active' : ''}
                        >
                            Customize
                            {/* <img src={avatar} alt="Avatar" className="right-icon" /> */}
                        </li>
                        {isAvatarSubcategoriesVisible === 'customize' && (
                            <ul className="subcategory-list">
                                <li onClick={() => handleAvatarSelect(avatar1)}>
                                    <img src={avatar1} alt="Avatar 1" className="avatar-thumbnail" />
                                    Saira
                                </li>
                                <li onClick={() => handleAvatarSelect(avatar2)}>
                                    <img src={avatar2} alt="Avatar 2" className="avatar-thumbnail" />
                                    Nimra
                                </li>
                                <li onClick={() => handleAvatarSelect(avatar3)}>
                                    <img src={avatar3} alt="Avatar 3" className="avatar-thumbnail" />
                                    Ayesha
                                </li>
                                <li onClick={() => handleAvatarSelect(avatar4)}>
                                    <img src={avatar4} alt="Avatar 4" className="avatar-thumbnail" />
                                    Ali
                                </li>
                                <li onClick={() => handleAvatarSelect(avatar5)}>
                                    <img src={avatar5} alt="Avatar 5" className="avatar-thumbnail" />
                                    Ahmed
                                </li>
                                <li onClick={() => handleAvatarSelect(avatar6)}>
                                    <img src={avatar6} alt="Avatar 6" className="avatar-thumbnail" />
                                    Usman
                                </li>
                            </ul>
                        )}
                    </ul>
                )}


                {activeItem === 'messages' && (
                    <ul>
                        <li
                            className={`sample-questions-toggle ${isSampleQuestionsVisible ? 'active' : ''}`}
                            onClick={() => setSampleQuestionsVisible(!isSampleQuestionsVisible)}
                        >
                            Sample Questions

                        </li>
                        {isSampleQuestionsVisible && (
                            <>
                                <ul className={`sample-questions-list ${isSampleQuestionsVisible ? 'visible' : ''}`}>
                                    {(isEnglish ? englishQuestions : urduQuestions).map((question, index) => (
                                        <li
                                            key={index}
                                            type="button"
                                            className="question-button"
                                            onClick={() => onQuestionClick(question)}
                                            disabled={isQueryDisabled}
                                            aria-label={`Ask about ${question}`}
                                        >
                                            {question}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </ul>
                )}

                {activeItem === 'favorites' && isFavoritesVisible && (
                    <div className="favorites-section">
                        <div className="category-container">
                            <div className="category-item">
                                <div onClick={() => setLikedResponsesVisible(!isLikedResponsesVisible)}>
                                    Liked Responses
                                </div>
                                {isLikedResponsesVisible && (
                                    <ul className="subcategory-list">
                                        {likedResponses.map((response, index) => (
                                            <li key={index}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: response.answer
                                                            .replace(/\n\n/g, "<br/>")                     // Newlines to <br/>
                                                            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")  // **text** to bold
                                                            .replace(/###(.*?)###/g, "<strong>$1</strong>")  // ###text### to bold
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </ul>

                                )}
                            </div>
                            <div className="category-item">
                                <div onClick={() => setDislikedResponsesVisible(!isDislikedResponsesVisible)}>
                                    Disliked Responses
                                </div>
                                {isDislikedResponsesVisible && (
                                    <ul className="subcategory-list">
                                        {dislikedResponses.map((response, index) => (
                                            <li key={index}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: response.answer
                                                            .replace(/\n\n/g, "<br/>")                     // Newlines to <br/>
                                                            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")  // **text** to bold
                                                            .replace(/###(.*?)###/g, "<strong>$1</strong>")  // ###text### to bold
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {activeItem === 'home' && (
                    <ul onClick={handleExit}>
                        <li>Stop Abuse Now <img src={home} alt="Home" className="right-icon" /></li>
                    </ul>
                )}
                {activeItem === 'chatHistory' && (
                    <div className="chat-history-container">
                        <h2 className="chat-history-heading">Chat History</h2>
                        <div className="chat-user-id-box">
                            User Name: {name}
                        </div>
                        <ul className="chat-history-list">
                            {renderChatHistory()}
                        </ul>
                    </div>
                )}
                {activeItem === 'expand' && (
                    <ul>
                        {/* <li>Collapse Sidebar <img src={expand} alt="Expand" className="right-icon" /></li> */}
                    </ul>
                )}
                {activeItem === 'settings' && (
                    <ul>
                        <li onClick={handleDarkModeToggle}>
                            Dark Mode: {darkMode ? 'Off' : 'On'}
                            {/* <img src={settings} alt="Settings" className="right-icon" /> */}
                        </li>
                    </ul>
                )}
            </div>
        );
    };

    return (
        <>                        <div className="avatarcontainer1">
            {isAvatarPageVisible && (
                <AvatarPage selectedAvatar={selectedAvatar} />
            )}
        </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>☰</button>
            <div className={`double-sidebar-container ${isSidebarVisible ? 'show-sidebar' : ''}`}>
                <div className="left-sidebar">
                    <div className="sidebar-content">
                        {sidebarItems.map((item) => (
                            <div
                                key={item.id}
                                className={`sidebar-item ${activeItem === item.id ? 'active' : ''}`}
                                onClick={() => handleItemClick(item.id)}
                            >
                                <img src={item.icon} alt={item.text} className="icon" />
                                {isExpanded && <span className="item-text">{item.text}</span>}
                            </div>
                        ))}
                    </div>

                    <div className="sidebar-bottom">
                        {bottomItems.map((item) => (
                            <div
                                key={item.id}
                                className={`sidebar-item ${activeItem === item.id ? 'active' : ''}`}
                                onClick={() => handleItemClick(item.id)}
                            >
                                <img src={item.icon} alt={item.text} className="icon" />
                                {isExpanded && <span className="item-text">{item.text}</span>}
                            </div>
                        ))}
                    </div>
                </div>

                {showSubcategories && (
                    <div className="right-sidebar">

                        <div className="avatarcontainerr">
                            {isAvatarPageVisible && (
                                <AvatarPage selectedAvatar={selectedAvatar} />
                            )}
                        </div>
                        <div className="renderd">
                            {renderDetailedContent()}
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};

export default Sidebar;