import React, { useState } from 'react';
import axios from 'axios';  // Add axios for API requests
import './Login.css';
const Login = ({ onLogin, onSwitchToSignup }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');  // State to store error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send a POST request to the backend to authenticate
      const response = await axios.post('http://localhost:2004/login', {
        email: email,
        password: password
      });
      // Assuming the backend sends back user ID in the response when login is successful
      console.log('Login successful', response.data);
      localStorage.setItem('userdata', response.data);  // Store the token
      localStorage.setItem('userId', response.data.id);  // Store the user ID
      console.log('User ID and token stored in local storage',response.data.id,response.data);
      onLogin();  // Call onLogin to handle successful login
    } catch (error) {
      // Handle errors if login fails
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setErrorMessage(error.response.data.message || 'Error occurred during login.');
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        setErrorMessage('No response from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        setErrorMessage('Error setting up login request');
      }
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account?{' '}
        <span className="switch-link" onClick={onSwitchToSignup}>
          Sign up
        </span>
      </p>
    </div>
  );
};

export default Login;
