import './App.css';
import React, { useState, useEffect } from 'react';
import LandingPage from './ComponentsMainPage/LandingPage';
import Login from './ComponentsMainPage/Login';
import Signup from './ComponentsMainPage/Signup';
import ChatBotPage from './ComponentsMainPage/ChatBotPage'; // 
import HardExit from './Component/hardexit';
import logo from "./images/logo.png"; // Correct import path
import crisis from "./images/crisis.png";
import wtsh3 from "./images/stopnow.png";
import safety from "./images/safety.png";
import shelter from "./images/shelter.png";
import emotional from "./images/emotional.png";
import complaint from "./images/compliant.png";
import right from "./images/human-rights.png";
import ngo from "./images/ngo.png";
import cost from "./images/save-money.png";
import relevant from "./images/puzzle.png";
import hours from "./images/24-hours.png";
import legalprocess from "./images/attestation.png";
import confidential from "./images/confidential.png";
import boy from "./images/boy.png"
import girl from "./images/woman.png"
import sun from './images/sun.png';
import moon from './images/moon.png';
import avatarImage from './images/avatarimage.png'
import jugnoproject from './images/jugnoproject.png';
import avatar1 from './images/avatar1.png';
import avatar2 from './images/avatar2.png';
import avatar3 from './images/avatar3.png';
import avatar4 from './images/avatar4.png';
import avatar5 from './images/avatar5.png';
import avatar6 from './images/avatar6.png';
import facebookIcon from './images/facebook.png';
import instagramIcon from './images/instagram.png';
import linkedinIcon from './images/linkedin.png';
// Create an array of unique images to preload
const imagesToPreload = [logo, crisis, wtsh3, safety, shelter, emotional, complaint, right, ngo, cost, relevant, hours, legalprocess, confidential, boy, girl, sun, moon, avatarImage, jugnoproject, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, facebookIcon, instagramIcon, linkedinIcon];


const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};



function App() {
  const userId = localStorage.getItem('userId'); // Check for user ID

  // Initialize currentPage based on user authentication status
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage || (userId ? 'chatbot' : 'landing'); // Go to chatbot if logged in or default to landing
  });

  const [isChatBotVisible, setChatBotVisible] = useState(false);
  const [isQuestionsPageVisible, setQuestionsPageVisible] = useState(true);
  const [isAvatarPageVisible, setAvatarPageVisible] = useState(true);
  const [query, setQuery] = useState('');
  const [isQueryDisabled, setQueryDisabled] = useState(false);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [likedResponses, setLikedResponses] = useState([]);
  const [dislikedResponses, setDislikedResponses] = useState([]);

  useEffect(() => {
    preloadImages(imagesToPreload);
  }, []);


  // Effect to update localStorage whenever currentPage changes
  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  const handleUpdateFavorites = (newLiked, newDisliked) => {
    setLikedResponses(newLiked);
    setDislikedResponses(newDisliked);
  };


  useEffect(() => {
    // Check if this is the first load by checking for a specific key
    if (!localStorage.getItem('hasLoadedBefore')) {
      localStorage.clear();  // Clears all local storage data
      localStorage.setItem('hasLoadedBefore', 'true'); // Set a flag so it doesn't run again
    }
  }, []);
  

  // Effect to update localStorage whenever currentPage changes
  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);


  const handleLogin = () => {
    setCurrentPage('chatbot');
    setChatBotVisible(true);
  };




  const handleSignup = () => {
    setCurrentPage('login');
  };

  const handleGuest = () => {
    setCurrentPage('chatbot');
    setChatBotVisible(true);
  };

  const handleSwitchToLogin = () => {
    setCurrentPage('login');
  };

  const handleSwitchToSignup = () => {
    setCurrentPage('signup');
  };

  const handleExit = () => {
    console.log("Exit confirmed. Navigating to landing page.");
    localStorage.clear();  // Clears all data in localStorage
    setCurrentPage('landing');  // Navigate to the landing page

  };


  const handleQuestionClick = (question) => {
    if (!isQueryDisabled) {
      setQuery(question); // Set the query to send
      setQueryDisabled(true); // Disable questions until the response is received
    }
  };

  const toggleQuestionsPage = () => {
    setQuestionsPageVisible((prev) => !prev);
  };

  const toggleAvatarPage = () => {
    setAvatarPageVisible((prev) => !prev);
  };

  const toggleSidebarWidth = () => {
    setSidebarExpanded((prev) => !prev);
  };

  const toggleDarkMode = (isDarkMode) => {
    setDarkMode(isDarkMode);
    document.body.classList.toggle("dark-mode", isDarkMode);
  };

  const handleAvatarSelect = (avatarSrc) => {
    setSelectedAvatar(avatarSrc);
  };

  return (
    <div className="App">
      {currentPage === 'landing' && (
        <LandingPage
          onLogin={() => setCurrentPage('login')}
          onSignup={() => setCurrentPage('signup')}
          onGuest={handleGuest}
        />
      )}

      {currentPage === 'login' && (
        <Login onLogin={handleLogin} onSwitchToSignup={handleSwitchToSignup} />
      )}
      {currentPage === 'signup' && (
        <Signup onSignup={handleSignup} onSwitchToLogin={handleSwitchToLogin} />
      )}
      {currentPage === 'chatbot' && (
        <ChatBotPage
          handleExit={handleExit}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          isSidebarExpanded={isSidebarExpanded}
          toggleQuestionsPage={toggleQuestionsPage}
          toggleAvatarPage={toggleAvatarPage}
          handleAvatarSelect={handleAvatarSelect}
          handleQuestionClick={handleQuestionClick}
          isQueryDisabled={isQueryDisabled}
          likedResponses={likedResponses}
          dislikedResponses={dislikedResponses}
          query={query}
          setQueryDisabled={setQueryDisabled}
          onUpdateFavorites={handleUpdateFavorites}
        />
      )}
                <HardExit/>
            
    </div>
  );
}

export default App;


