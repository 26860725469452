// src/ComponentsMainPage/ChatBotPage.js

import React, { useState, useEffect } from "react";
import Header from '../Component/header'; // Adjust the import according to your file structure
import Sidebar from '../Component/sidebar'; // Adjust the import according to your file structure
import Chatbot from '../Component/chatpage'; // Adjust the import according to your file structure

import avatartemp from "../images/avatar2.png";
import avatar1 from '../images/avatar1.png';
import avatar2 from '../images/avatar2.png';
import avatar3 from '../images/avatar3.png';
import avatar4 from '../images/avatar4.png';
import avatar5 from '../images/avatar5.png';
import avatar6 from '../images/avatar6.png';
import logo from "../images/logo.png";
import volume from "../images/volume.png";
import mic from '../images/mic.png';
import like from '../images/like.png';
import dislike from '../images/dislike.png';
import DomesticViolenceImage from "../images/DomesticViolenceImage.jpg"; // Correct import path
import SeekingHelpImage from "../images/SeekingHelpImage.jpeg";
import LegalRightsImage from "../images/LegalRightsImage.jpg";
import settings from '../images/settings.png';
import expand from '../images/expand.png';
import messages from '../images/help.png';
import favorites from '../images/favourite.png';
import chathistory from '../images/chathistory.png';
import samplequestions from '../images/samplequestions.png';
import home from '../images/home.png';
import avatar from '../images/avatar.png';

// Create an array of unique images to preload
const imagesToPreload = [avatartemp, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, logo, volume, mic, like, dislike, DomesticViolenceImage, SeekingHelpImage, LegalRightsImage, settings, expand, messages, favorites, chathistory, samplequestions, home, avatar];

const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};



const ChatBotPage = ({
  handleExit,
  darkMode,
  toggleDarkMode,
  isSidebarExpanded,
  toggleQuestionsPage,
  toggleAvatarPage,
  handleAvatarSelect,
  handleQuestionClick,
  isQueryDisabled,
  likedResponses,
  dislikedResponses,
  query,
  setQueryDisabled,
  onUpdateFavorites,
}) => {

  useEffect(() => {
    preloadImages(imagesToPreload);
  }, []);

  return (
    <>
      <Header
        onExit={handleExit} 
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        isSidebarExpanded={isSidebarExpanded}
      />
      <div className="main-content">
        <Sidebar
        onExit={handleExit} 
          onToggleQuestions={toggleQuestionsPage}
          onToggleAvatar={toggleAvatarPage}
          isExpanded={isSidebarExpanded}
          onAvatarSelect={handleAvatarSelect}
          onQuestionClick={handleQuestionClick}
          isQueryDisabled={isQueryDisabled}
          likedResponses={likedResponses} // Pass likedResponses
          dislikedResponses={dislikedResponses} // Pass dislikedResponses
        />
        <div className={`content-area ${isSidebarExpanded ? 'sidebar-expanded' : ''}`}>
          <Chatbot
            query={query}
            setQueryDisabled={setQueryDisabled}
            onUpdateFavorites={onUpdateFavorites}
          />
        </div>
      </div>
    </>
  );
};

export default ChatBotPage;
