import React from "react";
import "./OptionContainer.css";
import DomesticViolenceImage from "../images/DomesticViolenceImage.jpg"; // Correct import path
import SeekingHelpImage from "../images/SeekingHelpImage.jpeg";
import LegalRightsImage from "../images/LegalRightsImage.jpg";

const options = [
  {
    text: "What is Domestic Violence?",
    imageUrl: DomesticViolenceImage, // Replace with actual image URL
    query: "Explain domestic violence",
    description: "Information about the various types of domestic violence and its impacts."
  },
  {
    text: "Ways to Seek Help",
    imageUrl: SeekingHelpImage, // Replace with actual image URL
    query: "How to seek help against domestic violence?",

    description: "Helpful resources and organizations available for individuals affected by domestic violence."
  },
  {
    text: "Legal Rights and Protections",
    imageUrl: LegalRightsImage, // Replace with actual image URL
    query: "What are the legal rights of domestic violence victims?",
    description: "Information about the legal rights and protections against domestic violence in Pakistan."
  },

];

const OptionsContainer = ({ handleOptionClick }) => (
  <>
    <header className="occ-chat-header">
      <h3>AOA</h3>
      <h4>How can I help you today?</h4>
    </header>
    <div className="options-container1">
      {options.map((option, index) => {
        const isUrdu = /[\u0600-\u06FF]/.test(option.text);
        return (
          <div
            key={index}
            className="option"
            onClick={() => handleOptionClick(option.query, "search")}
          >
            <img
              src={option.imageUrl}
              alt={`Option ${index + 1}`}
              className="option-image"
            />
            <div
              className="option-text"
              dir={isUrdu ? "rtl" : "ltr"}
            >
              {option.text}
            </div>
            <div
              className="option-description"
              dir={isUrdu ? "rtl" : "ltr"}
            >
              {option.description}
            </div>
          </div>
        );
      })}
    </div>
  </>
);

export default OptionsContainer;
