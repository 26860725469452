import React, { useEffect } from 'react';
import './avatarpage.css';
import avatartemp from "../images/avatar2.png"
import avatar1 from '../images/avatar1.png';
import avatar2 from '../images/avatar2.png';
import avatar3 from '../images/avatar3.png';
import avatar4 from '../images/avatar4.png';
import avatar5 from '../images/avatar5.png';
import avatar6 from '../images/avatar6.png';



const AvatarPage = ({ selectedAvatar }) => {

  return (
    <div className="avatar-page">
      {selectedAvatar ? (
        <img src={selectedAvatar} alt="Selected Avatar"  className="avatar-img" />
      ) : (
        <img src={avatartemp}  className="avatar-img" />
      )}
    </div>
  );
};

export default AvatarPage;
