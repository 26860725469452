import React, { useEffect } from "react";
import deletecross from "../images/deletecross.png";
import sun from '../images/sun.png';
import moon from '../images/moon.png';
import logo from '../images/logo.png'; // Import the logo
import "./header.css";

const imagesToPreload = [deletecross, sun, moon, logo]; // Preload the logo as well

const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};

const Header = ({ onExit, darkMode, toggleDarkMode, isSidebarExpanded }) => {
  useEffect(() => {
    preloadImages(imagesToPreload);
  }, []);

  const handleExit = () => {
    onExit(); // This will trigger the exit and navigate to the landing page
  };

  return (
    <header className={`headerchatbot ${isSidebarExpanded ? 'sidebar-expanded' : ''}`}>
      <div className="header-left">
        <img src={logo} alt="Logo" className="chatbotheader-logo" /> {/* Add logo image here */}
        <span className="logo-name">Stop Abuse Now</span>
      </div>
      <div className="header-right">
        <button className="mode-button" onClick={() => toggleDarkMode(false)}>
          <img src={sun} alt="Light Mode" className="mode-icon" />
        </button>
        <button className="mode-button" onClick={() => toggleDarkMode(true)}>
          <img src={moon} alt="Dark Mode" className="mode-icon" />
        </button>
        <button className="exit-button" onClick={handleExit}>
          <img src={deletecross} alt="Exit" className="exit-icon" />
        </button>
      </div>
    </header>
  );
};

export default Header;
