
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chatpage.css";
import logo from "../images/logo.png";
import OptionsContainer from "./OptionContainer";
import volume from "../images/volume.png";
import mic from '../images/mic.png'
import PopupForm from './BasicDetails';
import like from '../images/like.png'
import dislike from '../images/dislike.png'
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const ChatBot = ({ backgroundImage, query, setQueryDisabled, onUpdateFavorites }) => {
  const [userInput, setUserInput] = useState("");
  const chatContentRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input element
  const [botState, setBotState] = useState("idle"); // idle, thinking, response
  const [chatHistory, setChatHistory] = useState([]);
  const [ratings, setRatings] = useState({});
  const [showHeaderAndOptions, setShowHeaderAndOptions] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [popupImages, setPopupImages] = useState([]);

  const [isVoicePlaying, setIsVoicePlaying] = useState(false);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [utterance, setUtterance] = useState(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [isQueryDisabled, setIsQueryDisabled] = useState(false);
  // New state to store animated text for each response by ID
  const [animatedTexts, setAnimatedTexts] = useState({});
  const [isListening, setIsListening] = useState(false);
  const [isSpeechSupported, setIsSpeechSupported] = useState(true);
  const recognitionRef = useRef(null);
  const [showTranslations, setShowTranslations] = useState({});
  const [translationErrors, setTranslationErrors] = useState({});
  const [translatedResponses, setTranslatedResponses] = useState({});
  const [showPop, setShowPop] = useState(true); // Show popup initially
  const [userDetailsSubmitted, setUserDetailsSubmitted] = useState(false);
  const [likedResponses, setLikedResponses] = useState([]);
  const [dislikedResponses, setDislikedResponses] = useState([]);
  let cancelRequest = false; // Cancel request flag
  useEffect(() => {
    
  
    if (showPopup && !userDetailsSubmitted && !cancelRequest) {
      console.log("Popup is shown, fetching initial data...");
  
      const fetchInitialData = async () => {
        try {
          const response = await axios.post(
            "https://stopabuse-eqfvd0h4dkcaerg2.centralindia-01.azurewebsites.net/api/generate_response",
            {
              query: "What is domestic violence? Keep the response in 6-7 words only",
              chat_history: [],
              name: "default_name",
              age: 0,
              gender: "unknown",
              additional_info: "No additional info"
            }
          );
  
          if (!cancelRequest && response.status === 200) {
            console.log("Pre-call succeeded:", response.data);
          }
        } catch (error) {
          if (!cancelRequest) {
            console.error("Error during pre-call:", error);
          }
        }
      };
  
      fetchInitialData();
    } else {
      console.log("Conditions not met for fetching initial data.");
    }
  
    // Cleanup function to avoid multiple calls
    return () => {
      cancelRequest = true;
    };
  }, [showPopup, userDetailsSubmitted]);
  
    
  useEffect(() => {
    // Load liked and disliked responses from local storage on component mount
    const storedLikedResponses = JSON.parse(localStorage.getItem('likedResponses')) || [];
    const storedDislikedResponses = JSON.parse(localStorage.getItem('dislikedResponses')) || [];
    setLikedResponses(storedLikedResponses);
    setDislikedResponses(storedDislikedResponses);
  }, []);
  
  useEffect(() => {
    if (chatContentRef.current) {
      const scrollHeight = chatContentRef.current.scrollHeight;
      chatContentRef.current.scrollTop = scrollHeight;
    }
  }, [chatHistory]);

  const handleLikeDislike = (message, isLike) => {
    const pair = { question: message.question || "", answer: message.text };

    // Check if the message is already liked or disliked
    const isLiked = likedResponses.some(item => item.answer === pair.answer);
    const isDisliked = dislikedResponses.some(item => item.answer === pair.answer);

    let updatedLikedResponses = [...likedResponses];
    let updatedDislikedResponses = [...dislikedResponses];

    if (isLike) {
      if (!isLiked) {
        // Add to liked responses if not already liked
        updatedLikedResponses = [...updatedLikedResponses, pair];
        // Remove from disliked responses if it was previously disliked
        updatedDislikedResponses = updatedDislikedResponses.filter(
          item => item.answer !== pair.answer
        );
      }
    } else {
      if (!isDisliked) {
        // Add to disliked responses if not already disliked
        updatedDislikedResponses = [...updatedDislikedResponses, pair];
        // Remove from liked responses if it was previously liked
        updatedLikedResponses = updatedLikedResponses.filter(
          item => item.answer !== pair.answer
        );
      }
    }

    setLikedResponses(updatedLikedResponses);
    setDislikedResponses(updatedDislikedResponses);

    // Save to local storage
    localStorage.setItem('likedResponses', JSON.stringify(updatedLikedResponses));
    localStorage.setItem('dislikedResponses', JSON.stringify(updatedDislikedResponses));

    // Log to console
    console.log('Liked Responses:', updatedLikedResponses);
    console.log('Disliked Responses:', updatedDislikedResponses);

    // Update favorites if needed
    if (onUpdateFavorites) {
      onUpdateFavorites(updatedLikedResponses, updatedDislikedResponses);
    }
  };

  // ... rest of the component

  const handleDetailsSubmitted = () => {
    setUserDetailsSubmitted(true);
    setShowPopup(false);
  };

  const translateToUrdu = async (text, messageId, retryCount = 0) => {
    try {
      const response = await axios.get(`https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|ur`);
      if (response.data && response.data.responseData && response.data.responseData.translatedText) {
        console.log("Translation successful:", response.data.responseData.translatedText);
        setTranslatedResponses(prev => ({
          ...prev,
          [messageId]: response.data.responseData.translatedText
        }));
        setTranslationErrors(prev => ({ ...prev, [messageId]: null }));
      } else {
        throw new Error('Translation failed');
      }
    } catch (error) {
      console.error('Translation error:', error);

      if (error.response && error.response.status === 429 && retryCount < 3) {
        const retryDelay = Math.pow(2, retryCount) * 1000;
        console.log(`Rate limited. Retrying in ${retryDelay}ms...`);
        setTranslationErrors(prev => ({ ...prev, [messageId]: `Limit reached. Retrying translation... (Attempt ${retryCount + 1}/3)` }));

        setTimeout(() => {
          translateToUrdu(text, messageId, retryCount + 1);
        }, retryDelay);
      } else {
        let errorMessage = 'Translation Error: Unable to translate the text.';
        if (error.response && error.response.status === 429) {
          errorMessage = 'Translation limit reached. Please try again later.';
        }
        setTranslationErrors(prev => ({ ...prev, [messageId]: errorMessage }));
        setTranslatedResponses(prev => ({ ...prev, [messageId]: null }));
      }
    }
  };

  const toggleTranslation = (messageId) => {
    setShowTranslations(prev => ({
      ...prev,
      [messageId]: !prev[messageId]
    }));
  };

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = 'en-US'; // Set language to English

      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setUserInput(transcript);
        if (inputRef.current) {
          inputRef.current.value = transcript;
        }
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };

      setIsSpeechSupported(true);
    } else {
      console.warn('Speech recognition not supported in this browser');
      setIsSpeechSupported(false);
    }
  }, []);

  const [speechLanguage, setSpeechLanguage] = useState('en-US');

  const toggleListening = () => {
    if (!isSpeechSupported) {
      alert('Speech recognition is not supported in your browser. Please type your message instead.');
      return;
    }

    if (isListening) {
      recognitionRef.current.stop();
    } else {
      setUserInput('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      recognitionRef.current.start();
    }
    setIsListening(!isListening);
  };


  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  useEffect(() => {
    if (botState === "response") {
      setTimeout(() => {
        setBotState("idle");
        setIsQueryDisabled(false);
      }, 3000);
    }
  }, [botState]);

  useEffect(() => {
    if (query) {
      handleUserInput(query);
    }
  }, [query]);

  // Update state to parent
  useEffect(() => {
    setQueryDisabled(isQueryDisabled);
  }, [isQueryDisabled, setQueryDisabled]);

  useEffect(() => {
    if (chatContentRef.current) {
      const scrollHeight = chatContentRef.current.scrollHeight;
      chatContentRef.current.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory]);

  const animateText = (id, text) => {
    if (typeof text !== 'string' || text.length === 0) {
      console.error('Invalid text for animation:', text);
      return;
    }
    console.log(`Starting animation for text: "${text}"`);
    let index = 0;
    const animateNextChar = () => {
      if (index <= text.length) {
        setAnimatedTexts(prevTexts => {
          const newText = text.slice(0, index);
          // console.log(`Animated text progress: "${newText}"`);
          return { ...prevTexts, [id]: newText };
        });
        index++;
        requestAnimationFrame(animateNextChar);
      }
    };
    requestAnimationFrame(animateNextChar);
  };


  const formatResponseText = (text) => {
    // Replace double newlines with <br/> for new lines
    let formattedText = text.replace(/\n\n/g, "<br/>");

    // Replace **text** with bold <strong> tags
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // Replace ###text with bold <strong> tags (if ### acts similar to bold)
    formattedText = formattedText.replace(/###(.*?)###/g, "<strong>$1</strong>");

    return formattedText;
  };


  const handleUserInput = async (query) => {
    setShowHeaderAndOptions(false);
    const newUserMessage = { sender: "user", text: query };
    const name = Cookies.get('name');
    const age = Cookies.get('age');
    const gender = Cookies.get('gender');
    const additionalInfo = Cookies.get('additionalInfo') || '';
    console.log('User details', name, age, gender, additionalInfo);
    setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
    setUserInput("");
    inputRef.current.value = "";
    setBotState("thinking");
    setIsQueryDisabled(true);

    const startTime = performance.now();

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    try {
      const response = await axios.post(
        "https://stopabuse-eqfvd0h4dkcaerg2.centralindia-01.azurewebsites.net/api/generate_response",
        { query: query, chat_history: chatHistory, name: name, age: age, gender: gender, additional_info: additionalInfo },
        { cancelToken: source.token }
      );

      const endTime = performance.now();
      const responseTime = (endTime - startTime) / 1000;

      const botResponseId = `bot_${Date.now()}`;
      const botResponse = {
        sender: "bot",
        text: formatResponseText(response.data.response || "No response available"),
        id: botResponseId,
        queryType: response.data.type || "general",
        responseTime,
      };

      // Translate the response to Urdu
      try {
        const translatedText = await translateToUrdu(botResponse.text);
        console.log("Translated text:", translatedText);
        setTranslatedResponses(prev => {
          const updated = { ...prev, [botResponseId]: translatedText };
          console.log("Updated translatedResponses:", updated);
          return updated;
        });
      } catch (error) {
        console.error("Translation error:", error);
      }

      if (response.data.type !== "general") {
        const qa = {
          question: query,
          answer: response.data.response,
          responseTime,
        };

        try {
          const res = await axios.post("https://stopabuse-eqfvd0h4dkcaerg2.centralindia-01.azurewebsites.net/qa/createQA", qa);
          console.log("QA created successfully:", res.data);
          botResponse.id = res.data._id;
          setChatHistory(prevHistory => [...prevHistory, botResponse]);
          setBotState("response");
          animateText(botResponse.id, botResponse.text);

          // Update translatedResponses with the new ID
          setTranslatedResponses(prev => {
            const updated = { ...prev, [res.data._id]: prev[botResponseId] };
            delete updated[botResponseId];
            console.log("Updated translatedResponses after QA creation:", updated);
            return updated;
          });
        } catch (error) {
          console.error("Error creating QA:", error);
          setChatHistory(prevHistory => [...prevHistory, botResponse]);
          setBotState("response");
          animateText(botResponse.id, botResponse.text);
        }
      } else {
        setChatHistory(prevHistory => [...prevHistory, botResponse]);
        setBotState("response");
        animateText(botResponse.id, botResponse.text);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Query canceled:", query);
        const errorMessage = {
          sender: "bot",
          text: "The query has been terminated.",
        };
        setChatHistory((prevHistory) =>
          prevHistory.slice(0, -1).concat(errorMessage)
        );
      } else {
        console.error("Error fetching bot response:", error);
        const errorMessage = {
          sender: "bot",
          text: "There seems to be a problem generating a response. Check your connection or try again in a few moments.",
        };
        setChatHistory((prevHistory) => [...prevHistory, errorMessage]);
      }
      setBotState("idle");
      setIsQueryDisabled(false); // Enable input if there's an error
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (inputRef.current.value.trim() && botState !== "thinking") {
      handleUserInput(inputRef.current.value);
      setUserInput('');
      inputRef.current.value = ''; // Clear input field
      if (isListening) {
        recognitionRef.current.stop();
        setIsListening(false);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input element when the component mounts
    }
  }, []);

  const handleStarClick = (responseId, rating, queryType) => {
    setRatings((prevRatings) => ({ ...prevRatings, [responseId]: rating }));

    if (queryType !== "general") {
      axios
        .post(
          `https://stopabuse-eqfvd0h4dkcaerg2.centralindia-01.azurewebsites.net/qa/setRating/${responseId}`,
          { rating }
        )
        .then((res) => {
          console.log("Rating set successfully:", res.data);
        })
        .catch((error) => {
          console.error("Error setting rating:", error);
        });
    }
  };

  const handleOptionClick = (query, action, images, response) => {
    if (isQueryDisabled) {
      return; // If a query is already in progress, do nothing
    }
    if (action === "search") {
      handleUserInput(query);
    } else if (action === "playVoice") {
      if (isVoicePlaying) {
        window.speechSynthesis.cancel();
        setIsVoicePlaying(false);
        setUtterance(null);
      } else {
        setSelectedResponse(response);
        speakText(response.text);
      }
    }
  };

  const speakText = (text) => {
    const newUtterance = new SpeechSynthesisUtterance(text);
    if (selectedVoice) {
      newUtterance.voice = selectedVoice;
    }
    newUtterance.lang = 'en-US'; // Set language to English
    newUtterance.onend = () => {
      setIsVoicePlaying(false);
      setUtterance(null);
    };
    setUtterance(newUtterance);
    window.speechSynthesis.speak(newUtterance);
    setIsVoicePlaying(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupImages([]);
  };

  const closeVoiceModal = () => {
    setShowVoiceModal(false);
  };

  const stopResponse = () => {
    if (botState === "thinking" && cancelTokenSource) {
      cancelTokenSource.cancel();
      setBotState("idle");
      setIsQueryDisabled(false); // Enable query input after stopping response
    }
  };

  const handleVoiceClick = (voice) => {
    setSelectedVoice(voice);
    setShowVoiceModal(false);
  };


  return (
    <div
      className="chatbot-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {showPop && !userDetailsSubmitted && (
        <PopupForm onClose={() => setShowPop(false)} onDetailsSubmitted={handleDetailsSubmitted} />
      )}
      {!showPop && userDetailsSubmitted && (
        <div>
          {showHeaderAndOptions && (
            <OptionsContainer handleOptionClick={handleOptionClick} />
          )}
          <div className="chat-window">
            <div className="chat-content" ref={chatContentRef}>
              {chatHistory.map((message, index) => (
                <div key={index} className={`message-bubble ${message.sender}`}>
                  <div className="message-header">
                    {message.sender === "bot" && (
                      <img src={logo} alt="Logo" className="bot-logo" />
                    )}
                    <div className="message-title">
                      {message.sender === "user" ? "You" : "ChatBot"}
                    </div>
                  </div>
                  <div className="message-text">
                    {message.sender === "bot" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: animatedTexts[message.id] || message.text.replace(/\n\n/g, "<br/>").replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>").replace(/###(.*?)###/g, "<strong>$1</strong>")
                        }}
                      />
                    ) : (
                      message.text
                    )}
                  </div>

                  {message.sender === "bot" && (
                    <>
                      <div className="voice-controls">
                        <div className="like-dislike-controls">
                          <button
                            className="like-button"
                            onClick={() => handleLikeDislike(message, true)}
                          >
                            <img src={like} alt="Like" className="like-icon" />
                          </button>
                          <button
                            className="dislike-button"
                            onClick={() => handleLikeDislike(message, false)}
                          >
                            <img src={dislike} alt="Dislike" className="dislike-icon" />
                          </button>
                        </div>

                        <div className="right-controls">
                          <button
                            className={`play-button ${isVoicePlaying ? "playing" : ""}`}
                            onClick={() => handleOptionClick(null, "playVoice", null, message)}
                          >
                            <img src={volume} alt="Voice" className="volume-icon" />
                          </button>
                          <button
                            className="voice-selector-button"
                            onClick={() => setShowVoiceModal(true)}
                          >
                            Select Voice
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {message.sender === "bot" && (
                    <>
                      <div className="separator"></div>
                      <div className="rating">
                        <span className="rate-text"></span>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            className={`star ${ratings[message.id] >= star ? "filled" : ""}`}
                            onClick={() =>
                              handleStarClick(message.id, star, message.queryType)
                            }
                          >
                            ★
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
              {botState === "thinking" && <span className="loader"></span>}
            </div>
            <form className="query-form" onSubmit={handleSubmit}>
              <div className="input-container">
                <div className={`reactive-fluid ${botState}`}>
                  <div className="dot"></div>
                </div>
                <input
                  type="text"
                  className="query-input"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  disabled={isQueryDisabled}
                  ref={inputRef}
                  placeholder={isListening ? "Listening..." : "Type your message..."}
                />
                <button
                  type="button"
                  className={`voice-input-button ${isListening ? 'listening' : ''}`}
                  onClick={toggleListening}
                  disabled={isQueryDisabled || !isSpeechSupported}
                  title={isSpeechSupported ? "Toggle voice input" : "Voice input not supported"}
                >
                  <img
                    src={mic}
                    alt="Microphone"
                    className={`mic-icon ${isListening ? 'listening' : ''}`}
                  />
                </button>
              </div>
              {botState === "thinking" ? (
                <button
                  type="button"
                  className="send-button"
                  onClick={stopResponse}
                >
                  Stop
                </button>
              ) : (
                <button
                  type="submit"
                  className="send-button"
                  disabled={isQueryDisabled}
                >
                  Send
                </button>
              )}
            </form>
          </div>
        </div>
      )}

      {/* Voice Selection Modal */}
      {showVoiceModal && (
        <div
          className="voice-modal-overlay"
          onClick={() => setShowVoiceModal(false)}
        >
          <div
            className="voice-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-voice-modal" onClick={closeVoiceModal}>
              ✖️
            </button>
            <div className="voice-list">
              {voices.slice(0, 5).map((voice, index) => ( // Limit to first 4 voices
                <button
                  key={index}
                  className={`voice-option ${selectedVoice === voice ? "selected" : ""}`}
                  onClick={() => handleVoiceClick(voice)}
                >
                  {voice.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ChatBot;
