

import './LandingPage.css'; // Ensure your styles are defined here
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../images/logo.png"; // Correct import path
import crisis from "../images/crisis.png";
import wtsh3 from "../images/stopnow.png";
import safety from "../images/safety.png";
import shelter from "../images/shelter.png";
import emotional from "../images/emotional.png";
import complaint from "../images/compliant.png";
import right from "../images/human-rights.png";
import ngo from "../images/ngo.png";
import cost from "../images/save-money.png";
import relevant from "../images/puzzle.png";
import hours from "../images/24-hours.png";
import legalprocess from "../images/attestation.png";
import confidential from "../images/confidential.png";
import boy from "../images/boy.png"
import girl from "../images/woman.png"
import sun from '../images/sun.png';
import moon from '../images/moon.png';
import avatarImage from '../images/avatarimage.png'
import jugnoproject from '../images/jugnoproject.png';
import avatar1 from '../images/avatar1.png';
import avatar2 from '../images/avatar2.png';
import avatar3 from '../images/avatar3.png';
import avatar4 from '../images/avatar4.png';
import avatar5 from '../images/avatar5.png';
import avatar6 from '../images/avatar6.png';
import facebookIcon from '../images/facebook.png';
import instagramIcon from '../images/instagram.png';
import linkedinIcon from '../images/linkedin.png';



const TestimonialCard = ({ name, title, image, rating, testimonial }) => (
    <div className="testimonial-card">
        <div className="testimonial-header">
            <img src={image} alt={name} className="testimonial-image" />
            <div className="testimonial-info">
                <h3>{name}</h3>
                <p className="title">{title}</p>
            </div>
        </div>
        <div className="rating">
            {[...Array(5)].map((_, i) => (
                <span key={i} className={i < rating ? "star filled" : "star"}>★</span>
            ))}
        </div>
        <p className="testimonial-text">{testimonial}</p>
    </div>
);


const testimonials = [
    {
        name: "Ayesha Malik",
        title: "Social Worker",
        image: girl,
        rating: 5,
        testimonial: "The chatbot's safety planning feature has been a lifesaver for many of the women I work with. It provides clear and practical steps that can be implemented immediately."
    },
    {
        name: "Aliya Ahmed",
        title: "Counselor",
        image: girl,
        rating: 3,
        testimonial: "The legal information provided by the chatbot is incredibly detailed and accurate. It helps women understand their rights and take the necessary legal actions."
    },
    {
        name: "Fatima Shah",
        title: "Advocate",
        image: girl,
        rating: 4,
        testimonial: "As a legal advocate, I find the chatbot's resources invaluable in guiding victims through the complexities of the legal system in Pakistan."
    },
    {
        name: "Nadia Iqbal",
        title: "Crisis Response Officer",
        image: girl,
        rating: 5,
        testimonial: "The immediate chat support provided by the bot is a critical resource for victims who need someone to talk to, offering them a first step towards seeking help."
    },
    {
        name: "Saira Khan",
        title: "NGO Coordinator",
        image: girl,
        rating: 3,
        testimonial: "The chatbot's connection to local support services is a game-changer. It ensures that victims get the help they need quickly and efficiently."
    },
    {
        name: "Aftab Ali",
        title: "Human Rights Activist",
        image: boy,
        rating: 5,
        testimonial: "The integration of safety planning with legal advice makes this chatbot an essential tool in the fight against domestic violence in Pakistan."
    },
    {
        name: "Mehwish Tariq",
        title: "Community Leader",
        image: girl,
        rating: 4,
        testimonial: "This chatbot provides a safe and anonymous platform for victims to seek help, which is vital in our culturally sensitive environment."
    },
    {
        name: "Sara Javed",
        title: "Volunteer",
        image: girl,
        rating: 4,
        testimonial: "The chatbot’s ability to guide users to relevant support services has made it an indispensable resource for our community outreach programs."
    },
    {
        name: "Razia Bibi",
        title: "Shelter Manager",
        image: girl,
        rating: 5,
        testimonial: "The information provided on legal rights is crucial for the women in our shelter. It empowers them to take control of their situations."
    },
    {
        name: "Zahid Qureshi",
        title: "Psychologist",
        image: boy,
        rating: 5,
        testimonial: "The mental health support offered by the chatbot is a vital component in helping victims recover from trauma."
    },
    {
        name: "Asma Perveen",
        title: "Law Student",
        image: girl,
        rating: 3,
        testimonial: "As a law student, the chatbot's resources have been incredibly useful in understanding the application of domestic violence laws in Pakistan."
    },
    {
        name: "Kashan Anwar",
        title: "Humanitarian Worker",
        image: boy,
        rating: 4,
        testimonial: "The chatbot is an incredible tool for raising awareness and providing immediate support to those who need it most."
    }

    // Add more testimonials here...
];


const LandingPage = ({ onLogin, onSignup, onGuest }) => {
    const navigate = useNavigate(); // Use useNavigate for navigation
    const [isNavActive, setIsNavActive] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleGetStarted = () => {
        navigate('/login'); // Redirect to login page
    };


    const benefitItems = [
        { icon: crisis, text: "Immediate Crisis Support" },
        { icon: confidential, text: "Confidential Legal Guidance" },
        { icon: safety, text: "Personalized Safety Plans" },
        { icon: shelter, text: "Access to Shelters" },
        { icon: complaint, text: "Legal Document Assistance" },
        { icon: emotional, text: "Emotional Support Resources" },
        { icon: right, text: "Information on Rights" },
        { icon: ngo, text: "Connection to NGOs" },
        { icon: hours, text: "24/7 Support" },
        { icon: legalprocess, text: "Guided Legal Processes" },
        { icon: cost, text: "Cost Free" },
        { icon: relevant, text: "Relevant Answers" }
    ];


    const BenefitItem = ({ icon, text }) => (
        <div className="benefit-item">
            <div className="benefit-icon">
                <img src={icon} alt={text} />
            </div>
            <p>{text}</p>
        </div>
    );


    const [impactStats, setImpactStats] = useState({
        chatCount: 0,
        countryCount: 0,
        communityCount: 0,
        volunteerCount: 0
    });

    useEffect(() => {
        fetchLatestStats();
    }, []);

    const fetchLatestStats = () => {
        // Simulating API call with placeholder data
        setImpactStats({
            chatCount: 32000,
            countryCount: 143,
            communityCount: 3000,
            volunteerCount: 102
        });
    };

    const ImpactStats = ({ number, label, icon }) => (
        <div className="impact-stat">
            <span className="impact-number">{number}</span>
            <p className="impact-label">{label}</p>
            <img src={icon} alt={label} className="impact-icon" />
        </div>
    );


    const toggleNav = () => {
        setIsNavActive(!isNavActive);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1038 && isNavActive) {
                setIsNavActive(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isNavActive]);



    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.add('light-mode');
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    const partnerData = [
        {
            name: "Jugno Project",
            image: jugnoproject,
            description: "The Jugno Project is a Pakistan-based initiative that provides shelter, legal, and emotional support to survivors of domestic violence. It aims to empower women and children by offering rehabilitation services and raising awareness to break the cycle of abuse.",
            link: "https://www.thejugnuproject.com/"
        },
    ];

    const [activeTab, setActiveTab] = useState('donate');
    const duplicatedTestimonials = [...testimonials, ...testimonials];
    const duplicatedItems = [...benefitItems, ...benefitItems];

    return (
        <div id="page-wrapper" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
            <header id="header">
                <div className="header-inner">
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="logoheader" />
                        <span className="name">Stop Abuse Now</span>
                    </div>
                    <button className={`menu-toggle ${isNavActive ? 'active' : ''}`} onClick={toggleNav}>
                        <span className="sr-only"></span>
                        <span>☰</span>
                    </button>
                    <nav className={`nav ${isNavActive ? 'active' : ''}`}>
                        <ul>
                            <li><a href="#afterheader1">Home</a></li>
                            <li><a href="#our-mission">Our Mission</a></li>
                            <li><a href="#benefits">Benefits</a></li>
                            <li><a href="#advocate">Advocates</a></li>
                            <li><a href="#testimonials">Testimonials</a></li>
                        </ul>
                    </nav>
                    <div className="auth-buttons">
                        { /* {<button onClick={onLogin} className="login-btn">Login</button>
                        <button onClick={onSignup} className="signup-btn">Sign Up</button> */}

                        <button onClick={onGuest} className="guest-btn">Enter Chatbot</button>
                        <div className="dark-mode-toggle">
                            <input
                                type="checkbox"
                                id="darkmode-toggle"
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                className="toggle-checkbox"
                            />
                            <label htmlFor="darkmode-toggle" className="toggle-label">
                                <img src={isDarkMode ? moon : sun} alt={isDarkMode ? "Moon" : "Sun"} className="toggle-icon" />
                                <span className="toggle-switch"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </header>

            <section id="afterheader1">
                <div className="content">
                    <div className="left">
                        <h2>Stop Abuse Now</h2>
                        <p>Domestic Violence Chatbot by Urdu<span className="highlight-x">X</span></p>
                        <button className="get-started" onClick={onGuest}>Single-Point-Solution</button>
                    </div>
                    <div className="avatar-containerr">
                        <img src={avatarImage} alt="Avatar" className="avatar-image" />
                    </div>
                </div>
            </section>

            <section className="gender-equality-section">
                <div className="container">
                    <div className="image-containeer">
                        <img
                            src={wtsh3}
                            alt="Empowering gender equality symbol"
                            className="equality-image"
                        />
                    </div>
                    <div className="content-container">
                        <h2 className="title">Raising Awareness Against Domestic Violence</h2>
                        <p className="genderdescription">
                            Join us in the fight to stop domestic violence. Every voice matters in creating a
                            safe environment where abuse is not tolerated, and victims are supported.
                        </p>

                        <div className="profile-section">
                            <div className="profile-images">
                                <img src={avatar6} alt="Profile 1" className="profile-img" />
                                <img src={avatar2} alt="Profile 2" className="profile-img" />
                                <img src={avatar4} alt="Profile 3" className="profile-img" />
                                <img src={avatar5} alt="Profile 1" className="profile-img" />
                                <img src={avatar3} alt="Profile 2" className="profile-img" />
                                <img src={avatar1} alt="Profile 3" className="profile-img" />
                            </div>
                            <p className="profile-text">
                                Join us in advocating for change and helping victims of domestic violence in Pakistan.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-mission">
                <h2>Our Mission</h2>
                <div className="underline"></div>
                <p>
                    Our mission is to raise awareness, create safe spaces for victims, and educate both abusers and the public on the impact of domestic violence. Through advanced AI, our chatbot provides vital resources, guidance, and emotional support. We are dedicated to empowering survivors, promoting justice, and ensuring no one faces abuse alone. Our goal is to build a safer, informed society where victims can confidently seek help and reclaim their lives.

                </p>
            </section>

            <section id="benefits">
                <div className="benefits-container-outside">
                    <h2>Benefits</h2>
                    <div className="underline"></div>
                    <div className="benefits-container">
                        <div className="benefits-scroll">
                            {duplicatedItems.map((item, index) => (
                                <BenefitItem key={index} icon={item.icon} text={item.text} />
                            ))}
                        </div>
                    </div>
                </div>
                {/* <h3>How It Works</h3>
                <div className="about-video">
                    <video controls>
                        <source src="path-to-your-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div> */}
            </section>

            <section id="advocate" className="our-approach">
                <h2 className="approach-title">Our Partners</h2>
                <p className="approach-description">
                We collaborate with organizations committed to supporting survivors of domestic violence. Our partners provide essential resources, legal assistance, and advocacy, working alongside us to create a safer, more supportive environment for those in need.
                </p>
                <div className="approach-cards">
                    {partnerData.map((partner, index) => (
                        <div key={index} className="approach-card">
                            <div className="card-image-container">
                                <img
                                    src={partner.image}
                                    alt={`Partner ${index + 1}`}
                                    className="card-image"
                                />
                            </div>
                            <h3 className="card-title">{partner.name}</h3>
                            <p className="card-description">{partner.description}</p>
                            <a href={partner.link} className="card-link" target="_blank" rel="noopener noreferrer">
                                Partner Page
                            </a>
                        </div>
                    ))}
                </div>
            </section>

            <section id="features">
                <h2>Features</h2>
                <p>StopAbuseNow Chatbot is Built upon multiple features</p>
                <div className="features-grid">


                    <div className="feature-card">
                        <div className="feature-card-header">
                            <div className="feature-icon">
                                <i className="fas fa-comments"></i>
                            </div>
                            <h3>Multilingual Chat 🌐💬 and Voice 🔊 </h3>
                        </div>
                        <p>Our chatbot provides instant, empathetic help in multiple languages, with both text and voice options to guide you through essential steps for safety and protection.</p>
                    </div>


                    <div className="feature-card">
                        <div className="feature-card-header">
                            <div className="feature-icon">
                                <i className="fas fa-shield-alt"></i>
                            </div>
                            <h3>Safety Planning 🛡️📋</h3>
                        </div>
                        <p>Get personalized advice to stay safe and prepare for emergencies. Our chatbot provides essential, situation-specific guidance to help you plan effectively to escape the abuse.</p>
                    </div>


                    <div className="feature-card">
                        <div className="feature-card-header">
                            <div className="feature-icon">
                                <i className="fas fa-info-circle"></i>
                            </div>
                            <h3>Know your rights ⚖️</h3>
                        </div>
                        <p> Discover key details on domestic violence laws in Pakistan. Learn how to file complaints and understand your legal rights with our comprehensive guide.</p>
                    </div>


                    <div className="feature-card">
                        <div className="feature-card-header">
                            <div className="feature-icon">
                                <i className="fas fa-hands-helping"></i>
                            </div>
                            <h3>Support Services 🤝💼</h3>
                        </div>
                        <p>Support Services: Connect with nearby shelters, counseling, and hotlines for extra help and resources when you need them most. We're here to guide you to the support you deserve</p>
                    </div>


                </div>
            </section>

            <div id="testimonials" className="testimonial-wrapper">
                <section id="testimonials">
                    <h2 className="testimonial-title">Testimonials</h2>
                    <div className="testimonial-container">
                        <div className="testimonial-row top">
                            {testimonials.map((testimonial, index) => (
                                <TestimonialCard key={`top-${index}`} {...testimonial} />
                            ))}
                        </div>
                        <div className="testimonial-row bottom">
                            {testimonials.map((testimonial, index) => (
                                <TestimonialCard key={`bottom-${index}`} {...testimonial} />
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <footer id="footer">
                <div className="footer-logofooter">
                    <h2>STOP ABUSE NOW</h2>
                    <p>Domestic Violence Chatbot by Urdu<span class="highlight-x">X</span></p>
                </div>
                <div className="footer-content">
                    <div className="footer-logo1">
                        <p>This chatbot is a lifeline for those affected by domestic violence, offering critical resources, legal guidance, and emotional support to empower and protect.</p>
                        <div className="social-icons">

                            <a href="https://www.facebook.com/share/cyT4aa3GSamv1Fed/?mibextid=LQQJ4d" aria-label="Facebook"><img src={facebookIcon} alt="Facebook" className="fab" /></a>
                            {/* <a href="https://www.instagram.com/stopabusenow_/" aria-label="Instagram"><img src={instagramIcon} alt="Instagram" className="fab" /></a> */}
                            <a href="https://www.linkedin.com/showcase/stopabusenow/" aria-label="LinkedIn"><img src={linkedinIcon} alt="LinkedIn" className="fab" /></a>

                        </div>
                    </div>
                    <div className="footer-links">
                        <h3>Want to Partner?</h3>
                        <ul>
                            <li>connect@stopabusenow.com </li>
                        </ul>
                    </div>
                    <div className="footer-contact">
                        <h3>Want to Volunteer?</h3>
                        <p><i className="fas fa-map-marker-alt"></i> Islamabad, Pakistan</p>
                        <p><i className="fas fa-envelope"></i> support@stopabusenow.com</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 UrduX. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
