import React from 'react';
import './hardexit.css';

const HardExit = () => {
  const handleLeaveSite = () => {
    window.location.href = 'https://www.google.com'; // Redirect to the desired URL
  };

  return (
    <div className="leave-site-container">
      <button className="leave-site-button" onClick={handleLeaveSite} >
        LEAVE THIS SITE
      </button>
    </div>
  );
};

export default HardExit;
